.UnmatchWeighbridge {
  display: flex;
  flex-direction: row;
  padding: 2ch;
  border: 1px solid #ccc;
}

.UnmatchWeighbridge > *:nth-child(1) {
  width: 20ch;
}

.UnmatchWeighbridge > *:nth-child(2) {
  flex: 1;
}