@import '../../../vars.scss';

.MultiSelect {
  display: flex;
  flex-direction: column;
  margin-top: $padding;

  & > span {
    @include Label();
    margin-bottom: 4px;
  }
  
  label {
    margin: 1ch 0;
    box-sizing: border-box;
    border-width: 0 0 1px 0;
    padding: 0 2ch;
    font-size: 14px;
    transition: 200ms;

    &:focus {
      border-color: $brandBlue;
      outline: none;
    }

    &:disabled {
      color: $brandDarkGrey;
      box-shadow: none;
      background: $brandLightGrey;
      border-color: transparent;
    }
  }

  &.Invalid > label {
    background: rgb(255, 230, 230);
  }
}