@import '../../../vars.scss';

a.button, button.button {
  height: 44px;

  & + .button {
    margin-left: $padding;
  }

  &.round {
    width: 46px;
    padding: 0;
  }

  padding: 0 30px;
  box-sizing: border-box;
  border: none;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  background: $brandBlue;
  color: $brandWhite;

  &.danger {
    background: $brandRed;
  }

  font-family: $font1;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  user-select: none;


  transition: box-shadow 200ms, opacity 200ms;
  border: 2px solid transparent;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &:focus {
    border-color: #00000033;
    outline: none;
  }

  &.wide {
    width: 100%;
  }

  &.disabled, &:disabled {
    &, &:hover, &:active {
      cursor: default;
      box-shadow: none;
      opacity: 1;
      color: $borderGrey;
      background: #E6E6EC;//$brandLightGrey;
    }
  }
}