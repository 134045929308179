$brandWhite: #ffffff;
$brandLightGrey: #F8F8F9;
$brandDarkGrey: #414042;
$brandMediumGrey: #8A959E;
$brandBlue: #2E92E9;
$brandRed: #FC3868;
$borderGrey: #A8A8A8;

$mediumLightGrey: #E9E9E9;

$font1: 'Open Sans', sans-serif;
$font2: 'Montserrat', sans-serif;
$font3: 'Lustria', sans-serif;

$bigShadow: 0 8px 40px rgba(0,0,0,0.14);

$padding: 10px;
$paddingMedium: $padding * 2;
$paddingLarge: $padding * 3;

@mixin Label() {
  font-family: $font2;
  color: $brandMediumGrey;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
}

@mixin WeightText() {
  font-family: $font1;
  font-weight: 900;
}

@keyframes ScreenIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@mixin AnimateScreenIn() {
  animation: ScreenIn 200ms ease-out;
}