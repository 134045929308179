@import '../../vars.scss';

@keyframes LoginEnter {
  from {
    opacity: 0;
    transform: translateY(15%);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.Login {
  animation: LoginEnter 800ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

  background: $brandWhite;
  width: 580px;
  padding: 85px 20px 60px 20px;
  box-sizing: border-box;
  margin: auto;
  box-shadow: $bigShadow;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 72px;

  @keyframes FormEnter {
    0% {
      opacity: 0;
      max-height: 0;
      padding-top: 0px;
    }

    50% {
      opacity: 0;
      max-height: 300px;
      padding-top: 30px;
    }

    100% {
      opacity: 1;
      max-height: 500px;
      padding-top: 30px;
    }
  }

  .form {
    opacity: 0;
    max-height: 0;
    padding-top: 0;

    &.entered {
      padding-top: 30px;
      animation: FormEnter 2000ms ease;
      opacity: 1;
      max-height: 500px;
    }

    width: 280px;
    box-sizing: border-box;
  }

  .Error {
    margin-top: 1em;
    padding: 1em;
    background-color: rgb(255, 238, 238);
  }
}


.Login {

  a.button,
  button.button {
    height: 44px;

    &.round {
      width: 46px;
      padding: 0;
    }

    padding: 0 30px;
    box-sizing: border-box;
    border: none;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background: $brandBlue;
    color: $brandWhite;

    font-family: $font1;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    user-select: none;


    transition: box-shadow 200ms,
    opacity 200ms;
    border: 2px solid transparent;

    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }

    &:focus {
      border-color: #00000033;
      outline: none;
    }

    &.wide {
      width: 100%;
    }

    &.disabled,
    &:disabled {

      &,
      &:hover,
      &:active {
        cursor: default;
        box-shadow: none;
        opacity: .5;
        color: $borderGrey;
        background: $brandLightGrey;
      }
    }
  }
}