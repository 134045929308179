@import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lustria|Montserrat:300,400,600,700,800,900|Open+Sans:300,400,600,700,800&display=swap');
@import './vars.scss';

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  color: $brandDarkGrey;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

a.button, button.button {
  height: 44px;

  &.round {
    width: 46px;
    padding: 0;
  }

  padding: 0 30px;
  box-sizing: border-box;
  border: none;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  background: $brandBlue;
  color: $brandWhite;

  font-family: $font1;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  user-select: none;


  transition: box-shadow 200ms, opacity 200ms;
  border: 2px solid transparent;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &:focus {
    border-color: #00000033;
    outline: none;
  }

  &.wide {
    width: 100%;
  }

  &.disabled, &:disabled {
    &, &:hover, &:active {
      cursor: default;
      box-shadow: none;
      opacity: 1;
      color: $borderGrey;
      background: $brandLightGrey;
    }
  }
}

html, body {
  height: 100vh;
  font-family: 'Inconsolata', 'Courier New', Courier, monospace;
}



.User {
  display: flex;
  width: 100ch;
  flex-direction: column;
  align-items: unset;
  padding: 1ch;
  margin: 2ch 4ch;
  border: 1px solid #ccc;

  & > * {
    flex: 1;
  }
}

.NewUser {
  padding: 2ch;
}

.TextWithLabel,
.CheckboxEditWithLabel {
  display: flex;
  flex-direction: row;
}

.TextWithLabel > * {
  margin-bottom: 1ch;
}

.TextWithLabel > span.label,
.CheckboxEditWithLabel > span.label {
  text-transform: uppercase;
  font-family: $font2;
  color: $brandMediumGrey;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
  width: 10ch;
}

.TextWithLabel > *:nth-child(2) {
  flex: 1;
}

.TextWithLabel > input {
  height: 32px;
  box-sizing: border-box;
  border: 1px solid $borderGrey;
  border-width: 0 0 1px 0;
  padding: 0 2ch;
  font-size: 14px;
  transition: 200ms;
}

.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.CheckboxEditWithLabel > *:nth-child(2) {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ScopeList {
  display: flex;
  flex-direction: column;
}

.UnassignedUser {
  display: flex;
  flex-direction: row;
  padding: 2ch;
  border: 1px solid #ccc;
}

.UnassignedUser > *:nth-child(1) {
  // width: 20ch;
  flex: 1;
}

.UnassignedUser > *:nth-child(2) {
  flex: 1;
}